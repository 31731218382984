import React from "react";
import Link from "./link";

import "./locations-accordion.scss";

const AccordionItemHead = ({ title, city, mapsLink, clickHandler }) => (
  <div className="location-list-head-wrapper">
    <button className="accordion-item--head" onClick={clickHandler}>
      <span className="title">{title}</span>
      <span className="city">{city}</span>
    </button>
    {/* <Link url={mapsLink} type="external" className="directions">
      <i>Anfahrt</i>
    </Link> */}
  </div>
);

const AccordionItemContent = React.forwardRef(
  ({ title, address, openingHours, image, style, extraContent }, ref) => (
    <div
      className="location-info accordion-item--content"
      style={style}
      ref={ref}
    >
      <div className="location-info-content">
        <h4>{title}</h4>
        <h5>{address}</h5>
        <ul className="hours">
          {openingHours.map((set, i) => (
            <li key={i}>
              <span className="term">{set.label}</span>
              <span className="data">{set.times}</span>
            </li>
          ))}
        </ul>
        <div className="location-info-extra-content">
          {extraContent}
        </div>
        {/* <div className="location-image">
        <img src={image} alt={title} />
      </div> */}
      </div>
      <button className="close">
        <span>schließen</span>
      </button>
    </div>
  )
);

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { contentMaxHeight: null, scrollHeight: null };
    this.handleClick = this.handleClick.bind(this);
    this.contentElement = React.createRef();
    this.updateHeight = this.updateHeight.bind(this);
  }

  updateHeight() {
    if (this.contentElement && this.contentElement.current) {
      this.setState({ scrollHeight: this.contentElement.current.scrollHeight });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateHeight);
    this.updateHeight();
  }

  handleClick() {
    this.props.onOpenItem(this.props.index);
  }

  render() {
    const { item } = this.props;
    return (
      <li className={`accordion-item ${this.props.active ? "active" : ""}`}>
        <AccordionItemHead
          title={item.title}
          city={item.city}
          mapsLink={item.mapsLink}
          clickHandler={this.handleClick}
        />
        <AccordionItemContent
          title={item.itle}
          address={item.address}
          openingHours={item.openingHours}
          image={item.image}
          style={{
            maxHeight: this.props.active ? `${this.state.scrollHeight + 20}px` : "0"
          }}
          extraContent={item.extraContent || false}
          ref={this.contentElement}
        />
      </li>
    );
  }
}

class LocationAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: null };
    this.handleOpenItem = this.handleOpenItem.bind(this);
  }

  handleOpenItem(index) {
    if (this.state.active === index) {
      this.setState({ active: null });
      if (this.props.onOpenItem) {
        this.props.onOpenItem(index);
      }
      return;
    }
    this.setState({ active: index });
    if (this.props.onOpenItem) {
      this.props.onOpenItem(index);
    }
  }

  render() {
    return (
      <ul className="location-list accordion">
        {this.props.locations.map((item, i) => (
          <AccordionItem
            item={item}
            key={i}
            onOpenItem={this.handleOpenItem}
            active={this.state.active === i}
            index={i}
          />
        ))}
      </ul>
    );
  }
}

export default LocationAccordion;
