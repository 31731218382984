import React from "react";
import TextImagegrid from "./text-imagegrid";
import LocationsAccordion from "./locations-accordion";
import Link from "./link";

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: null };
    this.handleOpenItem = this.handleOpenItem.bind(this);
  }

  handleOpenItem(index) {
    if (this.state.active === index) {
      this.setState({ active: null });
      return;
    }
    this.setState({ active: index });
  }

  render() {
    const { locations, children } = this.props;
    const { active } = this.state;
    const images = [
      [
        {
          active: active === null,
          image: this.props.images[0],
        },
        ...locations.map((location, index) => {
          return { active: index + 1 === active, image: location.image };
        }),
      ],
      this.props.images[1],
      this.props.images[2],
    ];

    if (this.state.active !== null) {
      images[0][0].active = false;
      images[0][this.state.active + 1].active = true;
    }
    return (
      <TextImagegrid
        className="locations"
        backgroundDark={true}
        images={images}
        reverse={this.props.reverse || false}
      >
        {children}
        <LocationsAccordion
          locations={locations}
          onOpenItem={this.handleOpenItem}
        />
      </TextImagegrid>
    );
  }
}

export default Locations;
