import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import Locations from "../components/locations";
import Link from "../components/link";

import "./standorte.scss";
import ArrowLink from "../components/arrow-link";

const LocationsPage = () => (
    <Layout>
        <SEO title="Standorte" />
        <Intro headline="Unsere Standorte" image={require("../images/neu/3frauen-smoothies.jpg")}>
            <p>
                Ein Besuch in o´mellis Juice & Smoothie Bar, dem Paradies für bewusste Genießer und fruchtige
                Schleckermäuler, lohnt sich allemal. Bei o´mellis Juice & Smoothie Bar kannst du dich mit gesundem
                Gewissen und ohne Reue vitaminreich betrinken!
            </p>
        </Intro>
        <Locations
            images={[
                require("../images/neu/bar_obst_2.jpg"),
                require("../images/neu/bar_obst_1.jpg"),
                require("../images/neu/frau_smoothie.jpg"),
            ]}
            locations={[
                {
                    title: "SCS Shopping City Süd",
                    city: "Vösendorf",
                    mapsLink: "https://goo.gl/maps/NqA3Lrrj4qj",
                    address: "Garden Plaza",
                    image: require("../images/standorte/scs.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - Mi.",
                            times: "09:00 - 19:00 Uhr",
                        },
                        {
                            label: "Do. - Fr.",
                            times: "09:00 - 20:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "09:00 - 18:00 Uhr",
                        },
                    ],
                    extraContent: (
                        <div className="scs-legumium">
                            <Link url="https://www.legumium.com/" type="external">
                                {" "}
                                <img
                                    src={require("../images/legumium-logo.png")}
                                    alt="Legumium Logo"
                                    width="600"
                                    height="163"
                                />{" "}
                            </Link>
                            <p>
                                Wir sind PICK UP POINT von{" "}
                                <Link url="https://www.legumium.com/" type="external">
                                    Legumium
                                </Link>
                                !
                            </p>
                        </div>
                    ),
                },
                {
                    title: "Hauptbahnhof",
                    city: "1100 Wien",
                    mapsLink: "https://goo.gl/maps/NqA3Lrrj4qj",
                    address: "Erdgeschoß",
                    image: require("../images/standorte/hauptbahnhof.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - So.",
                            times: "07:00 - 21:00 Uhr",
                        },
                    ],
                },
                {
                    title: "Mariahilfer Straße 126",
                    city: "1070 Wien",
                    mapsLink: "https://www.omellis.at/standorte",
                    address: "",
                    image: require("../images/standorte/maria-hilfer-strasse.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - Fr.",
                            times: "10:00 - 19:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "10:00 - 18:00 Uhr",
                        },
                    ],
                },
                {
                    title: "Fischapark",
                    city: "Wiener Neustadt",
                    mapsLink: "https://www.omellis.at/standorte",
                    address: "EG, Eingangsbereich",
                    image: require("../images/standorte/fischapark.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - Fr.",
                            times: "09:00 - 19:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "09:00 - 18:00 Uhr",
                        },
                    ],
                },
                {
                    title: "Donauzentrum",
                    city: "1220 Wien",
                    mapsLink: "https://www.omellis.at/standorte",
                    address: "EG, K01",
                    image: require("../images/standorte/donauzentrum2.jpeg"),
                    openingHours: [
                        {
                            label: "Mo. - Fr.",
                            times: "09:00 - 20:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "09:00 - 18:00 Uhr",
                        },
                    ],
                },
                {
                    title: "Designer Outlet Parndorf",
                    city: "7111 Parndorf",
                    mapsLink: "https://www.omellis.at/standorte",
                    address: "",
                    image: require("../images/standorte/parndorf.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - Fr.",
                            times: "09:30 - 19:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "09:00 - 18:00 Uhr",
                        },
                    ],
                },
            ]}
        >
            <h2 class="headline h3">Juice & Smoothie Bars</h2>
            <p>
                <ArrowLink url="https://hausbrot.at/produkte/smoothies/90/0/0" type="external">
                    NEU: Jetzt online bestellen!
                </ArrowLink>
            </p>
        </Locations>
        <Locations
            images={[
                require("../images/standorte/spar-stores.jpg"),
                require("../images/omellis-Smoothies-Flaschen.png"),
                require("../images/neu/wassermelone.jpg"),
            ]}
            locations={[
                {
                    title: "Spar Gourmet - Fleischmarkt 5",
                    city: "1010 Wien",
                    mapsLink: "https://www.omellis.at/standorte",
                    address: "",
                    image: require("../images/standorte/spar-stores.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - Fr.",
                            times: "07:40 - 20:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "07:40 - 18:00 Uhr",
                        },
                    ],
                },
                {
                    title: "Spar Pronto - Mariahilfer Straße 77-79",
                    city: "1070 Wien",
                    mapsLink: "https://www.omellis.at/standorte",
                    address: "",
                    image: require("../images/standorte/spar-pronto.jpg"),
                    openingHours: [
                        {
                            label: "Mo. - Fr.",
                            times: "07:40 - 20:00 Uhr",
                        },
                        {
                            label: "Samstag",
                            times: "07:40 - 18:00 Uhr",
                        },
                    ],
                },
            ]}
        >
            <h2 class="headline h3">Smoothies & Früchtebecher bei SPAR</h2>
            <p>O´mellis beliebteste Smoothies & Obstbecher bei SPAR</p>
        </Locations>
    </Layout>
);

export default LocationsPage;
